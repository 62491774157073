import {getPlatform} from '../helpers/util';
import {RequestToNative, NativeResponse} from './types';
import {SevenAppSDKError} from './error';
import nativeRequestVersion from './native-request-v1';
import constant from '../config';

export default class NativeApi {
  private static events: {[key: string]: any} = {};
  public static minimum7AppVersion = nativeRequestVersion.version;
  public static requestWithRedirect = nativeRequestVersion.withRedirect;
  public static requestWithFuction = nativeRequestVersion.withFuction;

  static async callToMobile(
    data: RequestToNative,
    timeout: number = constant.timeout.default
  ) {
    switch (getPlatform()) {
      case 'Android':
        if (!Android) {
          throw new SevenAppSDKError(
            'ERROR',
            'Platform feature missing Android',
            data.requestTrans
          );
        }

        if (!Android.webEchoMessage) {
          throw new SevenAppSDKError(
            'ERROR',
            'Not implemented Android webEchoMessage',
            data.requestTrans
          );
        }

        Android.webEchoMessage(JSON.stringify(data));
        break;
      case 'iOS':
        if (!window.webkit) {
          throw new SevenAppSDKError(
            'ERROR',
            'Platform feature missing iOS webkit',
            data.requestTrans
          );
        }

        if (!window.webkit.messageHandlers) {
          throw new SevenAppSDKError(
            'ERROR',
            'Not implemented window webkit messageHandlers',
            data.requestTrans
          );
        }
        window.webkit.messageHandlers.webEchoMessage.postMessage(
          JSON.stringify(data)
        );
        break;
    }

    return new Promise((resolve, reject) => {
      this.events[data.requestTrans] = [resolve, reject];
      setTimeout(() => {
        reject(
          new SevenAppSDKError(
            'TIMEOUT',
            'Time out for waiting native response',
            data.requestTrans
          )
        );
      }, timeout);
    });
  }

  static async mobileDone(response: string) {
    try {
      const responseData: NativeResponse = JSON.parse(response);
      if (this.events[responseData.requestTrans]) {
        const [resolve, reject] = this.events[responseData.requestTrans];
        if (responseData.data) {
          resolve(responseData);
        } else if (responseData.error) {
          reject(
            new SevenAppSDKError(
              responseData.error.errorCode,
              responseData.error.errorMessage,
              responseData.requestTrans
            )
          );
        }
        reject(
          new SevenAppSDKError(
            'UNKNOWN',
            'Unknown error',
            responseData.requestTrans
          )
        );
      }
    } catch (err) {
      throw new Error('On receive message error');
    }
  }

  static callToMobileSync(data: RequestToNative): void {
    switch (getPlatform()) {
      case 'Android':
        if (!Android) {
          throw new SevenAppSDKError(
            'ERROR',
            'Platform feature missing Android',
            data.requestTrans
          );
        }

        if (!Android.webEchoMessage) {
          throw new SevenAppSDKError(
            'ERROR',
            'Not implemented Android webEchoMessage',
            data.requestTrans
          );
        }

        Android.webEchoMessage(JSON.stringify(data));
        break;
      case 'iOS':
        if (!window.webkit) {
          throw new SevenAppSDKError(
            'ERROR',
            'Platform feature missing iOS webkit',
            data.requestTrans
          );
        }

        if (!window.webkit.messageHandlers) {
          throw new SevenAppSDKError(
            'ERROR',
            'Not implemented window webkit messageHandlers',
            data.requestTrans
          );
        }
        window.webkit.messageHandlers.webEchoMessage.postMessage(
          JSON.stringify(data)
        );
        break;
    }
  }
}
