import {PlatformUnsupportedError} from '../lib/error';
import * as nativeVersion from '../lib/version';

type MobilePlatform = 'iOS' | 'Android';

export function getPlatform(): MobilePlatform {
  const ua = window.navigator.userAgent;

  if (/[Aa]ndroid/.test(ua)) {
    return 'Android';
  }
  if (/iOS|iPhone|iPad/.test(ua)) {
    return 'iOS';
  }

  throw new PlatformUnsupportedError(ua);
}

export function getVersion(version: string) {
  switch (version) {
    case '1.0':
      return nativeVersion['v1'];
    default:
      return nativeVersion['v1'];
  }
}
