const constant = {
  timeout: {
    init: 3 * 1000, // 3s
    registerAllMember: 30 * 60 * 1000, // 30 minute
    registerAllMemberAndRedirect: 30 * 60 * 1000,
    registerCustomer: 30 * 60 * 1000,
    registerCustomerAndRedirect: 30 * 60 * 1000,
    getSealedToken: 20 * 1000,
    getUserStatus: 10 * 1000,
    default: 5 * 1000,
  },
  register: {
    defaultUrlSuccess: 'https://google.com',
    defaultUrlFail: 'https://google.com',
  },
};

export default constant;
