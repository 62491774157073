import {v4} from 'uuid';
import {InitConfig} from './lib/types';
import {getVersion} from './helpers/util';
import NativeApi from './lib/native-api-v1';
import constant from './config';
export class SevenAppSDK {
  private version: string;
  private clientId: string;
  public static nativeApi = NativeApi;

  constructor(initConfig: InitConfig) {
    this.version = initConfig.version;
    this.clientId = initConfig.clientId;
  }

  async init() {
    SevenAppSDK.nativeApi = getVersion(this.version);
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithFuction,
        requestTrans: v4(),
        clientID: this.clientId,
        minimumVersion: SevenAppSDK.nativeApi.minimum7AppVersion,
        functionName: 'checkCompatibility',
      },
      constant.timeout.init
    );
  }

  async registerAllMember(options: any) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithFuction,
        requestTrans: v4(),
        functionName: 'registerAllMember',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.requestWithFuction.data,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerAllMember
    );
  }

  async registerAllMemberAndRedirect(
    callbackUrlSuccess: string,
    callbackUrlFail: string,
    options?: any
  ) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithRedirect,
        requestTrans: v4(),
        functionName: 'registerAllMember',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.requestWithRedirect.data,
          callbackSuccess:
            callbackUrlSuccess || constant.register.defaultUrlSuccess,
          callbackFail: callbackUrlFail || constant.register.defaultUrlFail,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerAllMemberAndRedirect
    );
  }

  async registerCustomer(options?: any) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithFuction,
        requestTrans: v4(),
        functionName: 'registerCustomer',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.requestWithFuction.data,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerCustomer
    );
  }

  async registerCustomerAndRedirect(
    callbackUrlSuccess: string,
    callbackUrlFail: string,
    options?: any
  ) {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithRedirect,
        requestTrans: v4(),
        functionName: 'registerCustomer',
        clientID: this.clientId,
        data: {
          ...SevenAppSDK.nativeApi.requestWithRedirect.data,
          callbackSuccess:
            callbackUrlSuccess || constant.register.defaultUrlSuccess,
          callbackFail: callbackUrlFail || constant.register.defaultUrlFail,
          options: JSON.stringify(options) || '{}',
        },
      },
      constant.timeout.registerCustomerAndRedirect
    );
  }

  closeWebview(): void {
    SevenAppSDK.nativeApi.callToMobileSync({
      ...SevenAppSDK.nativeApi.requestWithFuction,
      requestTrans: v4(),
      functionName: 'closeWebView',
      clientID: this.clientId,
    });
  }

  openMyCoupon(): void {
    SevenAppSDK.nativeApi.callToMobileSync({
      ...SevenAppSDK.nativeApi.requestWithFuction,
      requestTrans: v4(),
      functionName: 'openMyCoupon',
      clientID: this.clientId,
    });
  }

  async getSealedToken() {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithFuction,
        requestTrans: v4(),
        functionName: 'getSealedToken',
        clientID: this.clientId,
      },
      constant.timeout.getSealedToken
    );
  }

  async getUserStatus() {
    return await SevenAppSDK.nativeApi.callToMobile(
      {
        ...SevenAppSDK.nativeApi.requestWithFuction,
        requestTrans: v4(),
        functionName: 'getUserStatus',
        clientID: this.clientId,
      },
      constant.timeout.getUserStatus
    );
  }
}
