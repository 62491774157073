export class PlatformUnsupportedError extends Error {
  constructor(public userAgent: string) {
    super(`Platform unsupported: ${userAgent}`);
  }
}

export class SevenAppSDKError extends Error {
  public errorCode: string;
  public errorMessage: string;
  constructor(errorCode: string, errorMessage: string, transactionId: string) {
    super(`[${errorCode}] ${errorMessage} (${transactionId})`);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SevenAppSDKError);
    }
    this.errorCode = errorCode;
    this.errorMessage = `${errorMessage} (${transactionId})`;
  }
}
