const nativeRequestVersion = {
  version: {
    android: '11.37.0',
    iOS: '11.37.0',
  },
  withRedirect: {
    requestTrans: '',
    functionName: '',
    clientID: '',
    data: {
      mode: 'callbackURL',
      callbackSuccess: '',
      callbackFail: '',
      options: '{}',
    },
  },
  withFuction: {
    requestTrans: '',
    functionName: '',
    clientID: '',
    // minimumVersion: {}, //it will be extend only on init function
    data: {
      mode: 'callbackFunction',
      callbackSuccess: 'SevenAppSDK.SevenAppSDK.nativeApi.mobileDone',
      callbackFail: 'SevenAppSDK.SevenAppSDK.nativeApi.mobileDone',
      options: '{}',
    },
  },
};

export default nativeRequestVersion;
